
const colors = {
    darknavy: "#242b49",
    navy:"#212f6d",
    lightnavy: "#4f5d75",
    gray: "#bfc0c0",
    lightgray: "#e5e8e8",
    white: "#ffffff",
    orange: "#ef8354",
}

export default colors